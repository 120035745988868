import React from "react";
import { Button } from "semantic-ui-react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";
import { get } from "lodash";

export const DS82_SELECT_VALUES = {
  GENDERS: "GENDERS",
  COUNTRIES: "COUNTRIES",
  HAIR_COLORS: "HAIR_COLORS",
  EYE_COLORS: "EYE_COLORS",
  COMMUNICATION_METHODS: "COMMUNICATION_METHODS",
  PHONE_COUNTRY_CODES: "PHONE_COUNTRY_CODES",
  PHONE_TYPES: "PHONE_TYPES",
  PASSPORT_STATUS: "PASSPORT_STATUS",
  NAME_CHANGE_OPTIONS: "NAME_CHANGE_OPTIONS",
  PASSPORT_TYPES: "PASSPORT_TYPES",
  PASSPORTBOOK_TYPES: "PASSPORTBOOK_TYPES",
  AMERICAN_STATES: "AMERICAN_STATES",
  EMERGENCY_CONTACT_RELATIONSHIPS: "EMERGENCY_CONTACT_RELATIONSHIPS",
};

const passportTypes = {
  book: 0,
  card: 1,
  both: 2,
};

const isEmpty = (value) => value === undefined;
const invalidValues = [undefined, "", null];

const isVersion1 = (applicant) => {
  // birthPlace is a new required field in version 2.
  const birthPlace = get(applicant, "birthPlace");
  return isEmpty(birthPlace);
};

const isBirthPlaceValid = (applicant) => {
  const birthStateProvince = get(applicant, "birthStateProvince");
  const birthCity = get(applicant, "birthCity");
  const birthPlace = get(applicant, "birthPlace");
  const isStateAndCity =
    !invalidValues.includes(birthCity) &&
    !invalidValues.includes(birthStateProvince);
  return !isStateAndCity && !invalidValues.includes(birthPlace);
};

const AddressSchema = (path) => ({
  streetAddress: {
    label: "Street Address",
    type: TYPES.TEXT,
    path,
  },
  streetAddress2: {
    label: "Street Address 2",
    type: TYPES.TEXT,
    path,
  },
  country: {
    label: "Country",
    type: TYPES.SELECT,
    values: DS82_SELECT_VALUES.COUNTRIES,
    path,
  },
  state: {
    label: "State",
    type: TYPES.SELECT,
    values: DS82_SELECT_VALUES.AMERICAN_STATES,
    path,
    customDependency: (applicant, path, schemaData) => {
      const country = get(applicant, `${path}.country`);
      return country === "USA";
    },
  },
  city: {
    label: "City",
    type: TYPES.TEXT,
    path,
  },
  apartmentUnit: {
    label: "Apartment/Unit",
    type: TYPES.TEXT,
    path,
    customDependency: (applicant, path, schemaData) => {
      const apartmentUnit = get(applicant, `${path}.apartmentUnit`);
      return !!apartmentUnit;
    },
  },
  apartmentOrUnit: {
    label: "Apartment/Unit",
    type: TYPES.TEXT,
    path,
    customDependency: (applicant, path) => {
      const apartmentUnit = get(applicant, `${path}.apartmentOrUnit`);
      return !!apartmentUnit;
    },
  },
  zipCode: {
    label: "Zip Code",
    type: TYPES.TEXT,
    path,
  },
});

const PhoneNumberSchema = (path) => ({
  countryCode: {
    label: "Country code",
    type: TYPES.SELECT,
    values: DS82_SELECT_VALUES.PHONE_COUNTRY_CODES,
    path,
  },
  number: {
    label: "Number",
    type: TYPES.TEXT,
    path,
  },
  label: {
    label: "Phone number type",
    type: TYPES.SELECT,
    values: DS82_SELECT_VALUES.PHONE_TYPES,
    customDependency: () => {
      const pathParts = path.split(".");
      // For Arrays. 'phoneNumber' needs to be last part of the path. Example: "additionalPhoneNumbers.$additionalPhoneNumbers.phoneNumber"
      const isAdditionalPhone =
        pathParts[pathParts.length - 1] === "phoneNumber";
      return !isAdditionalPhone;
    },
  },
});

const PassportBookInfoSchema = (path) => ({
  name: {
    label: "Full Name",
    type: TYPES.TEXT,
    path,
  },
  number: {
    label: `Most recent passport ${
      path === "passportBookInfo" ? "book" : "card"
    } number`,
    type: TYPES.TEXT,
    path,
  },
  issueDate: {
    label: "Issue Date",
    type: TYPES.DATE,
    path,
  },
  expDate: {
    label: "Expiration date",
    type: TYPES.DATE,
    path,
  },
  status: {
    label: "Status",
    type: TYPES.SELECT,
    values: DS82_SELECT_VALUES.PASSPORT_STATUS,
    default: "2",
    path,
  },
});

export const ds82Schema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory: {
        label: "Order ID history",
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.TEXT,
      },
      submissionPassword: {
        label: "Submission password",
        type: TYPES.TEXT,
      },
      backupCodes: {
        label: "Backup Codes",
        type: TYPES.MULTI_TEXT,
        maxLength: 10,
        path: "botAccountInformation",
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      userEmail: {
        label: "Email Address",
        type: TYPES.TEXT,
      },
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Last name",
        type: TYPES.TEXT,
      },
      middleName: {
        label: "Middle name",
        type: TYPES.TEXT,
      },
      ssn: {
        label: "Social Security Number",
        type: TYPES.TEXT,
        allowEditSecret: false,
        customDependency: (applicant) => (applicant.ssn ? true : false),
        disabled: (applicant) => (applicant.ssn ? false : true),
      },
      USSocialSecurityNumber: {
        label: "Social Security Number - (Last 4 digits)",
        type: TYPES.TEXT,
        disabled: true,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: DS82_SELECT_VALUES.GENDERS,
      },
      isChangingGender: {
        label: "Changing Gender?",
        type: TYPES.CHECKBOX,
      },
      isDifferentNameAtBirth: {
        label: "Different name at birth",
        type: TYPES.CHECKBOX,
      },
      additionalNames: {
        label: "Additional names",
        type: TYPES.MULTI_TEXT,
        dependsOn: "isDifferentNameAtBirth",
        dependantOnValues: [true],
      },
      additionalFullNames: {
        label: "Additional full names",
        newTable: true,
        type: TYPES.ARRAY,
        data: {
          firstName: {
            label: "First Name",
            type: TYPES.TEXT,
            path: "additionalFullNames.$additionalFullNames",
          },
          lastName: {
            label: "Last Name",
            type: TYPES.TEXT,
            path: "additionalFullNames.$additionalFullNames",
          },
        },
        customDependency: (applicant) => {
          const additionalFullNames = get(applicant, "additionalFullNames");
          const isDifferentNameAtBirth = get(
            applicant,
            "isDifferentNameAtBirth",
          );

          return (
            isDifferentNameAtBirth &&
            additionalFullNames !== undefined &&
            additionalFullNames.length > 0
          );
        },
      },
      birthDate: {
        label: "Date of birth",
        type: TYPES.DATE,
      },
      birthCountry: {
        label: "Country of birth",
        type: TYPES.SELECT,
        values: DS82_SELECT_VALUES.COUNTRIES,
      },
      birthStateProvince: {
        label: "State/Province of birth",
        type: TYPES.TEXT,
        customDependency: (applicant) => {
          return !isBirthPlaceValid(applicant);
        },
      },
      birthCity: {
        label: "City of birth",
        type: TYPES.TEXT,
        customDependency: (applicant) => {
          return !isBirthPlaceValid(applicant);
        },
      },
      birthPlace: {
        label: "Place of birth",
        type: TYPES.TEXT,
        customDependency: (applicant) => isBirthPlaceValid(applicant),
      },
      isUSCitizen: {
        label: "Are you a US citizen?",
        type: TYPES.CHECKBOX,
      },
      largePassportBookNeeded: {
        label: "Do you need a Large Passport Book?",
        type: TYPES.CHECKBOX,
      },
      height: {
        label: "Height",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          feets: {
            label: "Feet",
            type: TYPES.NUMBER,
            path: "height",
          },
          inches: {
            label: "Inches",
            type: TYPES.NUMBER,
            path: "height",
          },
        },
      },
      hairColor: {
        label: "Hair color",
        type: TYPES.SELECT,
        values: DS82_SELECT_VALUES.HAIR_COLORS,
      },
      eyeColor: {
        label: "Eye color",
        type: TYPES.SELECT,
        values: DS82_SELECT_VALUES.EYE_COLORS,
      },
      permanentAddress: {
        label: "Permanent Address",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("permanentAddress"),
      },
      isPermanentAddressSameAsMailing: {
        label: "Is your home address the same as the mailing address?",
        type: TYPES.CHECKBOX,
      },
      mailingAddress: {
        label: "Mailing Address",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("mailingAddress"),
      },
      inCareOf: {
        label: "In Care Of",
        type: TYPES.TEXT,
      },
      phoneNumber: {
        label: "Phone number",
        type: TYPES.NESTED,
        newTable: true,
        nested: PhoneNumberSchema("phoneNumber"),
      },
      additionalPhoneNumbers: {
        label: "Additional phone numbers",
        type: TYPES.ARRAY,
        data: {
          phoneNumber: {
            label: "Phone number",
            type: TYPES.NESTED,
            newTable: true,
            nested: PhoneNumberSchema(
              "additionalPhoneNumbers.$additionalPhoneNumbers.phoneNumber",
            ),
          },
          label: {
            label: "Phone label",
            type: TYPES.SELECT,
            values: DS82_SELECT_VALUES.PHONE_TYPES,
            path: "additionalPhoneNumbers.$additionalPhoneNumbers",
          },
        },
      },
      occupation: {
        label: "Occupation",
        type: TYPES.TEXT,
      },
      employeerOrSchool: {
        label: "Employer or School name",
        type: TYPES.TEXT,
      },
    },
  },
  passportInformation: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      trackingNumberHistory: {
        label: "Tracking Number History",
      },
      passportType: {
        label: "Passport type",
        type: TYPES.SELECT,
        values: DS82_SELECT_VALUES.PASSPORT_TYPES,
        customDependency: (applicant) => {
          return isVersion1(applicant);
        },
      },
      passportBookType: {
        label: "Passport book type",
        type: TYPES.SELECT,
        values: DS82_SELECT_VALUES.PASSPORTBOOK_TYPES,
        customDependency: (applicant, path, schemaData) => {
          const passportType = get(applicant, "passportType");
          if (isVersion1(applicant))
            return (
              passportType.toString() === passportTypes.book.toString() ||
              passportType.toString() === passportTypes.both.toString()
            );
          return false;
        },
      },
      passportBookInfo: {
        label: "Passport book information",
        type: TYPES.NESTED,
        newTable: true,
        nested: PassportBookInfoSchema("passportBookInfo"),
      },
      hasPassportCardInfo: {
        label: "Have you ever applied for or been issued a Passport Card?",
        type: TYPES.CHECKBOX,
      },
      passportCardInfo: {
        label: "Passport card information",
        type: TYPES.NESTED,
        newTable: true,
        nested: PassportBookInfoSchema("passportCardInfo"),
        dependsOn: "hasPassportCardInfo",
        dependantOnValues: [true],
      },
      isNameChanged: {
        label:
          "Is your name different from the name in the previous passport book?",
        type: TYPES.CHECKBOX,
      },
      nameChangeInfo: {
        label: "Name change information",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "isNameChanged",
        nested: {
          changedBy: {
            label: "Changed by",
            type: TYPES.SELECT,
            path: "nameChangeInfo",
            values: DS82_SELECT_VALUES.NAME_CHANGE_OPTIONS,
          },
          additionalNames: {
            label: "All other names previously used",
            type: TYPES.MULTI_TEXT,
            path: "nameChangeInfo",
          },
          additionalFullNames: {
            label: "All other full names previously used",
            newTable: true,
            type: TYPES.ARRAY,
            data: {
              firstName: {
                label: "First Name",
                type: TYPES.TEXT,
                path: "nameChangeInfo.additionalFullNames.$additionalFullNames",
              },
              lastName: {
                label: "Last Name",
                type: TYPES.TEXT,
                path: "nameChangeInfo.additionalFullNames.$additionalFullNames",
              },
            },
            customDependency: (applicant) => {
              const additionalFullNames = get(
                applicant,
                "nameChangeInfo.additionalFullNames",
              );
              return (
                additionalFullNames !== undefined &&
                additionalFullNames.length > 0
              );
            },
          },
          placeOfNameChange: {
            label: "Place of name change",
            type: TYPES.NESTED,
            newTable: true,
            path: "nameChangeInfo",
            nested: {
              state: {
                label: "State",
                type: TYPES.SELECT,
                values: DS82_SELECT_VALUES.AMERICAN_STATES,
                path: "nameChangeInfo.placeOfNameChange",
              },
              city: {
                label: "City",
                type: TYPES.TEXT,
                path: "nameChangeInfo.placeOfNameChange",
              },
            },
            customDependency: (applicant) => {
              return isVersion1(applicant);
            },
          },
          placeOfChange: {
            label: "Place of name change",
            type: TYPES.TEXT,
            path: "nameChangeInfo",
          },
          dateOfNameChange: {
            label: "Date of name change",
            type: TYPES.DATE,
            path: "nameChangeInfo",
          },
        },
      },
      documentTypeForPDF: {
        label: "Document type for PDF",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          book: {
            label: "Passport book",
            type: TYPES.CHECKBOX,
            path: "documentTypeForPDF",
          },
          card: {
            label: "Passport card",
            type: TYPES.CHECKBOX,
            path: "documentTypeForPDF",
          },
        },
      },
    },
  },
  emergencyInformation: {
    label: "Emergency Contact",
    type: TYPES.SEGMENT,
    data: {
      emergencyContact: {
        label: "Emergency contact",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          name: {
            label: "Full name",
            type: TYPES.TEXT,
            path: "emergencyContact",
          },
          relationship: {
            label: "Relationship to the Applicant",
            type: TYPES.SELECT,
            path: "emergencyContact",
            values: DS82_SELECT_VALUES.EMERGENCY_CONTACT_RELATIONSHIPS,
          },
          phoneNumber: {
            label: "Primary contact phone number",
            type: TYPES.NESTED,
            newTable: true,
            nested: PhoneNumberSchema("emergencyContact.phoneNumber"),
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema("emergencyContact.address"),
          },
        },
      },
      travelPlanned: {
        label: "Do you have any travel plans?",
        type: TYPES.CHECKBOX,
      },
      travelPlans: {
        label: "Travel plans",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          departureDate: {
            label: "Departure date",
            type: TYPES.DATE,
            path: "travelPlans",
          },
          returnDate: {
            label: "Return date",
            type: TYPES.DATE,
            path: "travelPlans",
          },
          countriesToVisitList: {
            label: "Countries to visit",
            type: TYPES.MULTI_TEXT,
            path: "travelPlans",
          },
          countriesToVisit: {
            label: "Countries to visit",
            type: TYPES.TEXT,
            path: "travelPlans",
            customDependency: (applicant) => {
              return isVersion1(applicant);
            },
          },
        },
        dependsOn: "travelPlanned",
        dependantOnValues: [true],
      },
    },
  },
};
