import React, {
  useCallback,
  useMemo,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { filter } from "rxjs/operators";
import moment from "moment";

import {
  Table,
  Button,
  Icon,
  Segment,
  Header,
  Loader,
  Popup,
  List,
} from "semantic-ui-react";
import NavigateButton from "../../../../../../../components/NavigateButton";
import toastr from "toastr";
import SmallConfirmPopup from "../../../../../../../components/SmallConfirmPopup";
import { isManagerOrAdmin } from "../../../../../../../constants/rolesConstants";
import {
  changePaymentStatusApi,
  getApplicantsApi,
  deleteApplicantApi,
  changePhoneNumbers,
} from "../../../../../../../apis/applicantApi";
import { SOCKET_EVENTS } from "../../../../../../../constants/socketEvents";
import socketService from "../../../../../../../services/socketService";
import ModalButton from "../../../../../../../components/ModalButton";
import PhoneBook from "../../../../../../../components/PhoneBook";
import { PRODUCT_NAMES_MAPPING } from "../../constants/productNames";
// import ApplicantRefund from "../ApplicantRefund";
import { usePagination } from "../../../../../../../hooks/usePagination.hook";
import { useSubscription } from "../../../../../../../hooks/useSubscription.hook";
import { useSort } from "../../../../../../../hooks/useSort.hook";
import {
  getPaymentStatusString,
  getWarningStatuses,
  getWarningPaymentStatuses,
  PAYMENT_STATUSES,
} from "../../constants/applicantStatuses";
import ModalForm from "../../../../../../../components/common/ModalForm";
import MarkAsCapturedForm from "../ApplicantView/components/MarkAsCapturedForm";
import { API_NAMES } from "../../EVisa/constants/apiNames";
import PassportPortraitPhoto from "../../EVisa/pages/ApplicantDocumentHandler/components/PassportPortraitPhoto";
import env from "../../../../../../../env";
import { generatePassportAppointmentsApi } from "../../../../../../../apis/tasksApi";
const ApplicantsTable = (
  {
    api,
    normalizedFilters = {},
    filtersRendered = null,
    tableColumns,
    additionalActions = () => null,
    zendeskView,
    isPassportPkoReady = false,
  },
  ref,
) => {
  const { user } = useSelector((state) => {
    return {
      user: state.currentUser,
    };
  });

  const { sortParams, handleSortChange, normalizedSortParams } = useSort(isPassportPkoReady ? {
    sortCriteria: 'createdAt',
    sortOrder: 'descending',
  } : {});

  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);

  const applicantsWrapper = useCallback(
    (query) => getApplicantsApi(query, api),
    [api],
  );

  const ITEMS_PER_PAGE = 20;

  const {
    items: applicants,
    Paginator,
    loadItems: loadApplicants,
    setItems: setApplicants,
    refreshItems: refreshApplicants,
    isLoading,
    totalItems,
  } = usePagination(applicantsWrapper, {
    initialLoad: false,
    clearOnLoad: false,
    limit: ITEMS_PER_PAGE,
  });

  useImperativeHandle(
    ref,
    () => ({
      setApplicants,
      refreshApplicants,
      isLoading,
    }),
    [setApplicants, refreshApplicants, isLoading],
  );

  useEffect(() => {
    loadApplicants(1, { ...normalizedFilters, ...normalizedSortParams });
  }, [loadApplicants, normalizedFilters, normalizedSortParams]);

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        const {
          data: { paymentStatus },
          data: newApplicant,
        } = event;
        toastr.info(`New '${paymentStatus}' applicant has been added', 'Ok`);
        setApplicants((applicants = []) => [newApplicant, ...applicants]);
      },
      [setApplicants],
    ),
    [filter((event) => event.type === SOCKET_EVENTS[api].NEW_APPLICANT)],
  );

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        const { _id, status } = event.data;
        setApplicants((applicants = []) =>
          applicants?.map((applicant) =>
            applicant._id === _id ? { ...applicant, status } : applicant,
          ),
        );
      },
      [setApplicants],
    ),
    [filter((event) => event.type === SOCKET_EVENTS[api].STATUS_CHANGED)],
  );

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        const {
          data: { _id, paymentStatus },
        } = event;
        setApplicants((applicants = []) =>
          applicants?.map((applicant) =>
            applicant._id === _id ? { ...applicant, paymentStatus } : applicant,
          ),
        );
      },
      [setApplicants],
    ),
    [
      filter(
        (event) => event.type === SOCKET_EVENTS[api].PAYMENT_STATUS_CHANGED,
      ),
    ],
  );

  const generatePassportAppointmentsHandler = useCallback(async () => {
    try {
      setIsGenerating(true);
      await generatePassportAppointmentsApi(selectedApplicants);
      toastr.success("Successfully generated!", "Success");
      setSelectedApplicants([]);
    } catch (error) {
      toastr.error(
        error.response?.data?.message || "Failed to generate",
        "Error",
      );
    } finally {
      setIsGenerating(false);
    }
  }, [selectedApplicants]);

  const deleteApplicantsHandler = useCallback(
    (applicantId) => async () => {
      try {
        await deleteApplicantApi(applicantId, api);
        setApplicants((applicants = []) =>
          applicants?.filter((applicant) => applicant._id !== applicantId),
        );
        toastr.success("Applicant successfully deleted!", "OK");
      } catch (error) {
        toastr.error("Applicant cannot be deleted!", "Error");
      }
    },
    [setApplicants, api],
  );

  const changePhoneNumberHandler = useCallback(
    (applicantId) => async (phoneNumbers) => {
      const {
        data: { data: newPhoneNumbers },
      } = await changePhoneNumbers(applicantId, phoneNumbers, api);
      setApplicants((applicants = []) =>
        applicants?.map((applicant) =>
          applicant._id !== applicantId
            ? applicant
            : { ...applicant, phoneNumbers: newPhoneNumbers },
        ),
      );
    },
    [setApplicants, api],
  );


  function getGovFeeForProduct(product) {
    const { quantity = 1, government_fee, exclude_from_conversion } = product;
    /**
     * This is to support old sales without the extra fields.
     * We calculate fees based on the product name and price.
     */
    if (!government_fee && !exclude_from_conversion) {
      return getGovFeeForProductWithoutDetails(product);
    }

    return (government_fee || exclude_from_conversion || 0) * quantity;
  }

  function getGovFeeForProductWithoutDetails({ name, price, quantity = 1 }) {
    const pricePerUnit = price / quantity;
    let fee = 0;

    if (name.toLowerCase().includes("3-5 week")) {
      if (pricePerUnit - 190 >= 119) {
        fee = 190;
      }
    }
    if (name.toLowerCase().includes("5-8 week")) {
      if (pricePerUnit - 130 >= 119) {
        fee = 130;
      }
    }
    if (name.toLowerCase().includes("passport card")) {
      fee = 30;
    }
    if (name.toLowerCase().includes("u.s. department fees")) {
      fee = price;
    }
    return fee;
  }

  const renderProductInfo = (cartProducts, applicant) => {
    if (!cartProducts || cartProducts.length === 0) {
      return <span>No products</span>;
    }

    const totalPrice = cartProducts.reduce(
      (acc, product) => acc + product.price,
      0,
    );

    let totalGovFee = cartProducts.reduce(
      (acc, product) => acc + getGovFeeForProduct(product),
      0,
    );

    const isUnder16 = applicant && applicant.birthDate
      ? moment().diff(moment(applicant.birthDate), "years") < 16
      : false;

    if (isUnder16 && totalGovFee >= 130) {
      totalGovFee -= 30;
    }

    return (
      <Segment style={{ width: '100%', maxWidth: '100%', overflow: 'auto' }}>
        <List style={{ width: '100%' }}>
          {cartProducts.map((product, index) => {
            return (
              <List.Item key={product.id || `product-${index}`} style={{ width: '100%' }}>
                <List.Content
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    border: "1px solid #eee",
                    padding: "8px",
                    borderRadius: "4px",
                    marginBottom: "1px",
                    width: "100%"
                  }}
                >
                  <List.Header style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "8px"
                  }}>
                    <span style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      wordBreak: "break-word",
                      flex: "1 1 auto"
                    }}>
                      {product.name}
                    </span>
                    <span style={{
                      fontSize: "12px",
                      whiteSpace: "nowrap"
                    }}>
                      {product.quantity} x {product.currency || 'USD'} {product.price}
                    </span>
                  </List.Header>
                </List.Content>
              </List.Item>
            );
          })}

          <List.Item style={{ width: '100%' }}>
            <List.Content style={{ width: '100%' }}>
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    marginTop: "8px",
                    borderTop: "1px solid #ddd",
                    paddingTop: "8px",
                    flexWrap: "wrap",
                    gap: "4px"
                  }}
                >
                  <span>Total:</span>
                  <span style={{
                    color: "green",
                    fontWeight: "600",
                    fontSize: "16px",
                    whiteSpace: "nowrap"
                  }}>
                    USD {totalPrice}
                  </span>
                </div>
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "4px",
                  backgroundColor: "rgba(239,68,68,.2)",
                  padding: "4px",
                  borderRadius: "4px",
                  marginTop: "8px",
                  flexWrap: "wrap",
                  gap: "4px"
                }}>
                  <span>Government Fee:</span>
                  <span style={{
                    color: "#db2828",
                    fontWeight: "500",
                    whiteSpace: "nowrap"
                  }}>
                    USD {totalGovFee}
                  </span>
                </div>
              </div>
            </List.Content>
          </List.Item>
        </List>
      </Segment>
    );
  };

  const shouldShowProducts = useMemo(() => {
    return api === API_NAMES.DS11 || api === API_NAMES.DS82;
  }, [api]);

  const actions = useMemo(() => {
    if (!applicants) {
      return;
    }
    return applicants.map((applicant) => {
      const { _id, paymentStatus } = applicant;

      let buttons = (
        <Popup
          content={"View/edit application"}
          trigger={
            <span>
              <NavigateButton
                route={`${api}/applicant/${_id}`}
                color="blue"
                size="mini"
                icon
              >
                <Icon name="edit" />
              </NavigateButton>
            </span>
          }
        />
      );
      if (paymentStatus === getPaymentStatusString(PAYMENT_STATUSES.Unpaid)) {
        buttons = (
          <Popup
            content={"Mark application as authorized/captured"}
            trigger={
              <span>
                <ModalForm
                  headerText="Mark application as authorized/captured"
                  loaderText="Preparing data..."
                  onSubmit={(result) =>
                    changePaymentStatusApi(
                      _id,
                      PAYMENT_STATUSES.Captured,
                      api,
                      result?.orderId,
                    )
                  }
                  className="change-type-modal"
                  closeOnSubmit
                  trigger={
                    <Button color="green" size="mini" icon>
                      <Icon name="dollar" />
                    </Button>
                  }
                >
                  <MarkAsCapturedForm applicant={applicant} />
                </ModalForm>
              </span>
            }
          />
        );
      }

      if (user && isManagerOrAdmin(user.roles)) {
        buttons = (
          <React.Fragment>
            {buttons}
            <Popup
              content={"Delete application"}
              trigger={
                <span>
                  <SmallConfirmPopup
                    header="Are you sure you want to delete the applicant?"
                    content={
                      <Button positive floated="right" content="Delete" />
                    }
                    callback={deleteApplicantsHandler(_id)}
                  >
                    <Button color="red" size="mini" icon>
                      <Icon name="trash" />
                    </Button>
                  </SmallConfirmPopup>
                </span>
              }
            />
          </React.Fragment>
        );
      }

      const { canCall, canSendMessage } = user.permissions;
      if (canCall || canSendMessage) {
        buttons = (
          <React.Fragment>
            {buttons}
            <Popup
              content={"Call the customer"}
              trigger={
                <span>
                  <ModalButton
                    buttonProps={{ color: "green", size: "mini" }}
                    buttonClassName={"icon"}
                    buttonIcon="call"
                    modalComponent={
                      <PhoneBook
                        numbers={applicant.phoneNumbers}
                        applicant={applicant}
                        onChange={changePhoneNumberHandler(_id)}
                      />
                    }
                  ></ModalButton>
                </span>
              }
            />
          </React.Fragment>
        );
      }

      if ([API_NAMES.DS11, API_NAMES.DS82].includes(api) && isPassportPkoReady) {
        buttons = (
          <React.Fragment>
            {buttons}
            <ModalButton
              buttonProps={{ color: "blue", size: "mini" }}
              buttonClassName={"icon"}
              buttonIcon="photo"
              closeButton
              disabled={applicant.lastPhotoRequestedAt && moment(applicant.lastPhotoRequestedAt).isAfter(moment().subtract(env.PASSPORT_PORTAIT_REQUEST_INTERVAL, 'minutes'))}
              modalStyle={{
                maxWidth: "90vw",
                maxHeight: "90vh",
                width: "auto",
                height: "auto",
                margin: "auto",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                padding: "10px",
              }}
              modalComponent={
                <PassportPortraitPhoto
                  id={_id}
                  api={api}
                  applicant={applicant}
                />
              }
            />
          </React.Fragment>
        );
      }

      buttons = (
        <React.Fragment>
          {buttons}
          {additionalActions(applicant)}
        </React.Fragment>
      );

      return <Table.Cell className="action-buttons" style={{ display: 'flex', justifyContent: 'flex-end' }}>{buttons}</Table.Cell>;
    });
  }, [
    applicants,
    api,
    changePhoneNumberHandler,
    deleteApplicantsHandler,
    user,
    additionalActions,
  ]);

  return (
    <Segment.Group style={{ display: "table", width: "100%" }}>
      <Segment className="tableTitle adminTable" inverted>
        <Header size="large" floated="left">
          {PRODUCT_NAMES_MAPPING[api]} Users - Total {totalItems}
        </Header>
        <div style={{ display: "flex", alignItems: "center" }}>
          {filtersRendered}
          {[API_NAMES.DS11, API_NAMES.DS82].includes(api) && isPassportPkoReady && (
            <Button
              color="purple"
              loading={isGenerating}
              disabled={
                selectedApplicants.length >= env.PASSPORT_GENERATE_MAX_COUNT ||
                isGenerating ||
                selectedApplicants.length === 0
              }
              onClick={generatePassportAppointmentsHandler}
            >
              Generate {selectedApplicants.length} /
              {env.PASSPORT_GENERATE_MAX_COUNT}
            </Button>
          )}
        </div>
      </Segment>
      {!applicants && (
        <Segment>
          <Loader active />
        </Segment>
      )}
      {applicants && (
        <Table sortable celled striped>
          <Table.Header>
            <Table.Row>
              {Object.entries(tableColumns).map(([columnName, column]) => {
                const { label } = column;
                // Skip rendering the checkbox header if not isPassportPkoReady for DS11 and DS82
                if ((columnName === "checkBox" || columnName === "siteId") && !isPassportPkoReady &&
                  [API_NAMES.DS11, API_NAMES.DS82].includes(api)) {
                  return null;
                }
                return (
                  <Table.HeaderCell
                    key={columnName}
                    sorted={
                      columnName === sortParams.sortCriteria
                        ? sortParams.sortOrder
                        : null
                    }
                    onClick={handleSortChange(columnName)}
                  >
                    {label === 'checkbox' ? '' : label}
                  </Table.HeaderCell>
                );
              })}
              {shouldShowProducts && isPassportPkoReady && (
                <Table.HeaderCell>Products</Table.HeaderCell>
              )}
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {applicants.map((applicant, index) => (
              <Table.Row key={applicant._id}>
                {Object.entries(tableColumns).map(([columnName, column]) => {
                  const { value, color = () => null } = column;
                  let tdValue;

                  if (value) {
                    if (columnName === "_id") {
                      tdValue = value(applicant, zendeskView);
                    } else if (columnName === "checkBox") {
                      // Skip rendering the checkbox completely if not isPassportPkoReady
                      if (!isPassportPkoReady) {
                        return null;
                      }
                      tdValue = value(
                        applicant,
                        selectedApplicants,
                        setSelectedApplicants,
                      );
                    }
                    else if (columnName === "siteId") {
                      // Skip rendering the checkbox completely if not isPassportPkoReady
                      if (!isPassportPkoReady) {
                        return null;
                      }
                      tdValue = value(applicant);
                    }
                    else {
                      tdValue = value(applicant);
                    }
                  } else {
                    tdValue = applicant[columnName];
                  }

                  return (
                    <Table.Cell
                      negative={
                        getWarningStatuses().includes(applicant.status) ||
                        getWarningPaymentStatuses().includes(
                          applicant.paymentStatus,
                        )
                      }
                      key={applicant.id + columnName}
                      style={{ backgroundColor: color(applicant) }}
                    >
                      {tdValue}
                    </Table.Cell>
                  );
                })}
                {shouldShowProducts && isPassportPkoReady && (
                  <Table.Cell>
                    {renderProductInfo(applicant.cartProducts, applicant)}
                  </Table.Cell>
                )}
                {actions[index]}
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={Object.keys(tableColumns).length + 3}>
                <Paginator />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
    </Segment.Group>
  );
};

export default forwardRef(ApplicantsTable);
